<template>
  <b-col class="col-12 col-md-8 pt-5 px-3 p_b__3">
    <h4 class="mb-4 font-weight-normal text-muted">
      {{ $t("page.branches.title") }}
    </h4>
    <div>
      <b-card no-body class="px-3 py-3 mb-3">
        <h6 class="mb-0 font-weight-normal text-uppercase">
          {{ $t("generalSettings") }}
        </h6>
        <hr class="my-3 mx-n3 divider" />
        <b-form-checkbox v-model="shareProjects" switch>
          <span class="text-muted ml-3">{{
            $t("page.branches.shareProjects")
          }}</span>
        </b-form-checkbox>
      </b-card>
      <b-card no-body class="px-3 py-3 mb-3">
        <div class="d-flex align-items-center">
          <h6 class="mb-0 font-weight-normal text-uppercase">
            {{ $t("sideMenu.branches") }}
          </h6>
          <b-button
            size="sm ml-auto text-uppercase"
            @click="openAddBranchModal"
          >
            {{ $t("button.add") }}
          </b-button>
        </div>
        <hr class="my-3 mx-n3 divider" />
        <div v-for="branch in branchList" :key="branch.id">
          <branch-list-item
            :branch="branch"
            :users="branchUsersList(branch._id)"
            @editBranch="openEditBranchModal"
            @deleteBranch="openDeleteBranchModal"
          />
        </div>
        <div v-if="isEmptyBranchList">
          <div class="text-center font-weight-bold">
            {{ $t("page.branches.emptyList") }}
          </div>
        </div>
      </b-card>
    </div>
    <branch-modal
      :modalState="modalState"
      :modalType="modalType"
      :branch="branch"
      @createBranch="createBranch"
      @updateBranch="updateBranch"
      @deleteBranch="deleteBranch"
      @updateBranchModalState="updateBranchModalState"
    />
  </b-col>
</template>

<script>
import BranchListItem from "../components/Branches/BranchListItem.vue";
import BranchModal from "../components/Branches/BranchModal";
export default {
  components: {
    BranchListItem,
    BranchModal,
  },
  created() {
    this.initShareProjects();
  },
  watch: {
    shareProjects: {
      handler: function () {
        if (this.dataLoaded) this.changeShareProjectsStatus();
      },
      immediate: false,
    },
  },
  data() {
    return {
      dataLoaded: false,
      shareProjects: false,
      modalState: false,
      modalType: "ADD",
      branch: {},
    };
  },
  computed: {
    client() {
      return { ...this.$store.getters["auth/getUserAccount"] };
    },
    branchList() {
      return this.$store.getters["multiuser/getBranches"];
    },
    isEmptyBranchList() {
      return this.branchList.length === 0;
    },
    userList() {
      // remove "parent" client user from userList by missing branchId property
      return this.$store.getters["multiuser/getUsers"].filter((user) => {
        return user.branchId !== undefined;
      });
    },
  },
  methods: {
    initShareProjects() {
      // Use 'dataLoaded' in order to prevent send request on init (in watch)
      this.$nextTick(() => {
        this.dataLoaded = true;
      });
      this.shareProjects = this.client.shareProjects;
    },
    changeShareProjectsStatus() {
      const client = this.client;
      client.shareProjects = this.shareProjects;
      this.updateUserAccount(client);
    },
    updateUserAccount(client) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store.commit("auth/SET_USER_ACCOUNT", client);
      this.$store
        .dispatch("auth/updateClient")
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },
    branchUsersList(branchId) {
      return this.userList.filter((user) => {
        return user.branchId === branchId;
      });
    },
    updateBranchModalState(state) {
      this.modalState = state;
    },
    openAddBranchModal() {
      this.branch = {};
      this.modalType = "ADD";
      this.updateBranchModalState(true);
    },
    openEditBranchModal(branch) {
      this.branch = branch;
      this.modalType = "EDIT";
      this.updateBranchModalState(true);
    },
    openDeleteBranchModal(branch) {
      this.branch = branch;
      this.modalType = "DELETE";
      this.updateBranchModalState(true);
    },
    closeBranchModal() {
      this.updateBranchModalState(false);
    },
    createBranch(branch) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("multiuser/createBranchByClientId", branch)
        .then(this.updateBranchModalState(false))
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },
    updateBranch(branch) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("multiuser/updateBranchByClientIdAndBranchId", branch)
        .then(this.updateBranchModalState(false))
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },
    deleteBranch(branch) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("multiuser/deleteBranchByClientIdAndBranchId", branch._id)
        .then(this.updateBranchModalState(false))
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },
  },
};
</script>

<style scoped>
.divider {
  border-top: 1px black solid;
}
.action__icon {
  width: 14px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
</style>
