<template>
  <div>
    <b-row class="no-gutters align-items-center">
      <b-col class="col-4 col-md-8">
        <b-row class="no-gutters">
          <b-col class="col-12 col-md-6 font-weight-bold">
            {{ branchName }}
          </b-col>
          <b-col class="col-12 col-md-6 text-md-center">
            <div class="btn text-muted body__2 p-0" @click="goToPage('Users')">
              {{ usersCount }} {{ $t("sideMenu.users") }}
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="col-8 col-md-4 text-right">
        <b-button
          size="sm"
          class="ml-auto mr-2 text-uppercase"
          variant="outline-gray-dark"
          @click="editBranch"
        >
          <span>{{ $t("button.edit") }}</span>
        </b-button>
        <b-button
          size="sm ml-auto text-uppercase"
          variant="red"
          @click="deleteBranch"
        >
          {{ $t("button.delete") }}
        </b-button>
      </b-col>
    </b-row>
    <hr class="my-3" />
  </div>
</template>

<script>
export default {
  props: {
    branch: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  computed: {
    branchName() {
      return this.branch.name;
    },
    usersCount() {
      return this.users.length;
    },
  },
  methods: {
    editBranch() {
      this.$emit("editBranch", this.branch);
    },
    deleteBranch() {
      this.$emit("deleteBranch", this.branch);
    },
    goToPage(routeName) {
      if (this.$route.name !== routeName) {
        this.isHovered = false;
        this.isExpandItemActive = false;
        this.$router.push({ name: routeName });
      }
    },
  },
};
</script>

<style scoped>
.action__icon {
  width: 14px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
</style>
