<template>
  <b-modal
    v-model="state"
    size="lg"
    content-class="rounded-0"
    footer-class="border-top-0 justify-content-start"
    centered
    no-close-on-esc
    no-close-on-backdrop
    @cancel="onCancel"
  >
    <template v-slot:modal-header>
      <h5 class="mb-0 text-uppercase font-weight-bold">
        {{ $t(modalTitle) }}
      </h5>
    </template>
    <template v-slot:default>
      <div v-if="modalType === 'DELETE'">
        <h5 class="text-red">
          {{
            $t("page.branches.deleteBranchMsg", {
              branchName: activeBranch.name,
            })
          }}
        </h5>
      </div>
      <div v-else>
        <validation-observer ref="observer">
          <b-row>
            <b-col class="col-12 col-md-6">
              <validation-provider
                :name="$t('name')"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('name')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="inputValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col class="col-12 col-md-6">
              <validation-provider
                :name="$t('address')"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('address')"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    v-model="address"
                    :state="inputValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-12 col-md-6">
              <validation-provider
                :name="$t('city')"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('city')"
                  label-for="city"
                >
                  <b-form-input
                    id="city"
                    v-model="city"
                    :state="inputValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col class="col-12 col-md-6">
              <validation-provider
                :name="$t('country')"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('country')"
                  label-for="position"
                >
                  <b-form-input
                    id="country"
                    v-model="country"
                    :state="inputValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-12 col-md-6">
              <validation-provider
                :name="$t('province')"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('province')"
                  label-for="province"
                >
                  <b-form-input
                    id="province"
                    v-model="province"
                    :state="inputValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col class="col-12 col-md-6">
              <validation-provider
                :name="$t('phone')"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('phone')"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="phone"
                    :state="inputValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                :name="$t('zip')"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('zip')"
                  label-for="zip"
                >
                  <b-form-input
                    id="zip"
                    v-model="zip"
                    :state="inputValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col class="col-12 col-md-6">
              <validation-provider
                :name="$t('VAT')"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('VAT')"
                  label-for="vat"
                >
                  <b-form-input
                    id="vat"
                    v-model="vat"
                    :state="inputValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </div>
    </template>
    <template v-slot:modal-footer="{ cancel }">
      <b-row class="no-gutters justify-content-start w-100">
        <b-col class="ml-auto col-auto mr-1 mb-1">
          <b-button variant="outline-gray-dark" @click="cancel">{{
            $t("button.cancel")
          }}</b-button>
        </b-col>
        <b-col class="col-auto mr-1 mb-1">
          <b-button @click="onProceed" :disabled="!formValid">
            {{ $t(actionBtn) }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalState: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: "ADD",
    },
    branch: {
      type: Object,
      required: true,
    },
  },
  watch: {
    modalState: {
      handler: function () {
        if (
          this.modalState &&
          (this.modalType === "EDIT" || this.modalType === "DELETE")
        ) {
          this.activeBranch = { ...this.branch };
        }
      },
      immediate: false,
    },
    activeBranch: {
      // Workaround/Hack in order to retrieve validation-observer status by watching activeUser object changes
      // https://github.com/logaretm/vee-validate/issues/2609
      handler: function () {
        if (this.modalType === "DELETE") {
          this.formValid = true;
        } else if (this.$refs.observer) {
          this.$refs.observer
            .validate()
            .then((status) => (this.formValid = status));
        }
      },
      deep: true,
      immediate: false,
    },
  },
  data() {
    return {
      formValid: false,
      activeBranch: {
        name: "",
        phone: "",
        country: "",
        city: "",
        address: "",
        province: "",
        zip: "",
        vat: "",
      },
    };
  },
  methods: {
    resetForm() {
      this.formValid = false;
      this.activeBranch = {
        name: "",
        phone: "",
        country: "",
        city: "",
        address: "",
        province: "",
        zip: "",
        vat: "",
      };
    },
    onCancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    onProceed() {
      if (this.modalType === "DELETE") {
        this.$emit("deleteBranch", this.activeBranch);
      } else if (this.modalType === "EDIT") {
        this.$emit("updateBranch", this.activeBranch);
      } else {
        this.$emit("createBranch", this.activeBranch);
      }
      this.resetForm();
    },
    inputValidationState({ dirty, validated, valid = null, untouched }) {
      if (!untouched && (dirty || validated)) return valid ? null : valid;
      else return null;
    },
  },
  computed: {
    modalTitle() {
      switch (this.modalType) {
        case "EDIT":
          return "page.branches.editBranchTitle";
        case "DELETE":
          return "page.branches.deleteBranchTitle";
        default:
          return "page.branches.createBranchTitle";
      }
    },
    actionBtn() {
      switch (this.modalType) {
        case "EDIT":
          return "button.save";
        case "DELETE":
          return "button.confirm";
        default:
          return "button.createBranch";
      }
    },
    state: {
      get() {
        return this.modalState;
      },
      set(state) {
        this.$emit("updateBranchModalState", state);
      },
    },
    name: {
      get() {
        return this.activeBranch.name;
      },
      set(name) {
        this.activeBranch.name = name;
      },
    },
    phone: {
      get() {
        return this.activeBranch.phone;
      },
      set(phone) {
        this.activeBranch.phone = phone;
      },
    },
    country: {
      get() {
        return this.activeBranch.country;
      },
      set(country) {
        this.activeBranch.country = country;
      },
    },
    city: {
      get() {
        return this.activeBranch.city;
      },
      set(city) {
        this.activeBranch.city = city;
      },
    },
    address: {
      get() {
        return this.activeBranch.address;
      },
      set(address) {
        this.activeBranch.address = address;
      },
    },
    province: {
      get() {
        return this.activeBranch.province;
      },
      set(province) {
        this.activeBranch.province = province;
      },
    },
    zip: {
      get() {
        return this.activeBranch.zip;
      },
      set(zip) {
        this.activeBranch.zip = zip;
      },
    },
    vat: {
      get() {
        return this.activeBranch.vat;
      },
      set(vat) {
        this.activeBranch.vat = vat;
      },
    },
  },
};
</script>

<style></style>
